import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Languages } from './Languages';
import { Language } from '../../hooks/useLanguage';
import { LANG_TEXT } from '../../lang/community';
import { scrollToTop } from '../../utils/utils';

type MobileMenuProps = {
	isMenuOpen: boolean;
	language: Language;
	isUkr: boolean;
	closeMenu: () => void;
	homePageRoute: string;
	mentorshipPageRoute: string;
	resultsPageRoute: string;
	indicatorsPageRoute: string;
};

export const MobileMenu: FC<MobileMenuProps> = ({
	isMenuOpen,
	language,
	isUkr,
	closeMenu,
	homePageRoute,
	mentorshipPageRoute,
	resultsPageRoute,
	indicatorsPageRoute,
}) => {
	return (
		<Menu isMenuOpen={isMenuOpen}>
			<NavSection>
				<NavBlock>
					<Link
						onClick={() => {
							window.location.href = 'https://goshawty.club';
						}}
						draggable="false"
						to={homePageRoute}
					>
						{LANG_TEXT[language].header.community}
					</Link>
					<Link
						onClick={() => {
							closeMenu();
							scrollToTop();
						}}
						draggable="false"
						to={mentorshipPageRoute}
					>
						{LANG_TEXT[language].header.mentorship}
					</Link>
					<Link
						onClick={() => {
							closeMenu();
							scrollToTop();
						}}
						draggable="false"
						to={resultsPageRoute}
					>
						{LANG_TEXT[language].header.results}
					</Link>
					<Link
						onClick={() => {
							closeMenu();
							scrollToTop();
						}}
						draggable="false"
						to={indicatorsPageRoute}
					>
						{LANG_TEXT[language].header.indicators}
					</Link>
				</NavBlock>
				<Divider />
				<NavBlock>
					<Link
						draggable="false"
						to="https://t.me/+-yiy2-jRZBQxYmE6"
						target="_blank"
					>
						TELEGRAM
					</Link>
					<Link
						draggable="false"
						to="https://instagram.com/goshawtyxx"
						target="_blank"
					>
						INSTAGRAM
					</Link>
					<Link
						draggable="false"
						to="https://youtube.com/@GoShawtyFx"
						target="_blank"
					>
						YOUTUBE
					</Link>
					<Link draggable="false" to="https://t.me/goshawtyxx" target="_blank">
						{LANG_TEXT[language].header.contact}
					</Link>
				</NavBlock>
			</NavSection>
			<Languages
				size="big"
				language={language}
				isUkr={isUkr}
				onClick={closeMenu}
			/>
		</Menu>
	);
};

const Menu = styled.div<{ isMenuOpen: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-bottom: 200px;
	top: 100px;
	position: fixed;
	height: 100vh;
	width: 100vw;
	background: ${({ theme }) => theme.blackBg};
	transition: 0.5s;
	transform: ${({ isMenuOpen }) => (isMenuOpen ? '' : 'translateX(100vw)')};
	font-family: 'Roboto Slab', serif;

	@media (min-width: 951px) {
		display: none;
		visibility: hidden;
	}
`;

const NavSection = styled.div`
	padding: 30px 50px;

	a {
		display: block;
		color: ${({ theme }) => theme.whiteText};
		text-decoration: none;
		font-size: 25px;
		padding: 10px 0;
	}

	> div {
		&:nth-of-type(1) {
			padding-bottom: 25px;
		}
		&:nth-of-type(3) {
			padding-top: 25px;
		}
	}
`;

const NavBlock = styled.div`
	display: flex;
	flex-direction: column;

	&:nth-of-type(3) {
		align-items: flex-end;

		@media (max-width: 350px) {
			align-items: flex-start;
		}
	}
`;

const Divider = styled.div`
	position: absolute;
	width: 80vw;
	height: 2px;
	/* transform: rotate(-45deg) translate(0px, -15px); */
	background: ${({ theme }) => theme.whiteText};

	@media (max-width: 350px) {
		display: none;
		visibility: hidden;
	}
`;
