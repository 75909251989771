import React from 'react';
import ReactDOM from 'react-dom/client';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from './ErrorRoute';
import Root from './root/Root';
import { Community } from './components/Community/Community';
import { Mentorship } from './components/Mentorship/Mentorship';
import { Results } from './components/Results/Results';
import { Redirect } from './components/Redirect/Redirect';
import { Indicators } from './components/Indicators/Indicators';
import { TELEGRAM_LINKS } from './lang/redirects';

const router = createBrowserRouter([
	{
		path: '/',
		element: <Root />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: '/',
				element: (
					<Redirect
						link="https://goshawty.club"
						customText="GoShawty Club Website"
					/>
				),
			},
			{
				path: '/mentorship',
				element: <Mentorship />,
			},
			{
				path: '/results',
				element: <Results />,
			},
			{
				path: '/indicators',
				element: <Indicators />,
			},
			{
				path: '/ru',
				element: <Community />,
			},
			{
				path: '/ru/mentorship',
				element: <Mentorship />,
			},
			{
				path: '/ru/results',
				element: <Results />,
			},
			{
				path: '/ru/indicators',
				element: <Indicators />,
			},
			{
				path: '/en',
				element: <Community />,
			},
			{
				path: '/en/mentorship',
				element: <Mentorship />,
			},
			{
				path: '/en/results',
				element: <Results />,
			},
			{
				path: '/en/indicators',
				element: <Indicators />,
			},
			{
				path: '/telegram',
				element: <Redirect link={TELEGRAM_LINKS.goshawty} />,
			},
			{
				path: '/channel',
				element: <Redirect link={TELEGRAM_LINKS.goshawtyDiscord} />,
			},
			{
				path: '/dxdy0x',
				element: <Redirect link={TELEGRAM_LINKS.dxdy0x} />,
			},
			{
				path: '/cage',
				element: <Redirect link={TELEGRAM_LINKS.cage} />,
			},
			{
				path: '/vladikk317',
				element: <Redirect link={TELEGRAM_LINKS.vladikk317} />,
			},
			{
				path: '/bu1bazawr',
				element: <Redirect link={TELEGRAM_LINKS.bu1bazawr} />,
			},
			{
				path: '/yankie',
				element: <Redirect link={TELEGRAM_LINKS.yankie} />,
			},
			{
				path: '/vicione',
				element: <Redirect link={TELEGRAM_LINKS.vicione} />,
			},
			{
				path: '/sleg',
				element: <Redirect link={TELEGRAM_LINKS.sleg} />,
			},
		],
	},
]);

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);
