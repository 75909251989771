import React from 'react';
import Logo from '../../assets/footerLogo.png';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useLanguage } from '../../hooks/useLanguage';
import { LANGUAGE_OPTIONS, LANG_TEXT } from '../../lang/community';
import { scrollToTop } from '../../utils/utils';

export const Footer = () => {
	const language = useLanguage();
	const isUkr = language === LANGUAGE_OPTIONS[0];
	const homePageRoute = `/${isUkr ? '' : language + '/'}`;
	const mentorshipPageRoute = `${homePageRoute}mentorship`;
	const resultsPageRoute = `${homePageRoute}results`;
	const indicatorsPageRoute = `${homePageRoute}indicators`;

	return (
		<>
			<FooterWrap>
				<FooterContent>
					<LinkWrap>
						<div>
							<a href="https://goshawty.club" draggable="false">
								{LANG_TEXT[language].header.community}
							</a>
							<Link
								draggable="false"
								to={mentorshipPageRoute}
								onClick={scrollToTop}
							>
								{LANG_TEXT[language].header.mentorship}
							</Link>
							<Link
								draggable="false"
								to={resultsPageRoute}
								onClick={scrollToTop}
							>
								{LANG_TEXT[language].header.results}
							</Link>
							<Link
								draggable="false"
								to={indicatorsPageRoute}
								onClick={scrollToTop}
							>
								{LANG_TEXT[language].header.indicators}
							</Link>
						</div>
						<Divider />
						<div>
							<Link
								draggable="false"
								to="https://t.me/+-yiy2-jRZBQxYmE6"
								target="_blank"
							>
								TELEGRAM
							</Link>
							<Link
								draggable="false"
								to="https://instagram.com/goshawtyxx"
								target="_blank"
							>
								INSTAGRAM
							</Link>
							<Link
								draggable="false"
								to="https://youtube.com/@GoShawtyFx"
								target="_blank"
							>
								YOUTUBE
							</Link>
							<Link draggable="false" to="https://t.me/goshawtyxx">
								{LANG_TEXT[language].header.contact}
							</Link>
						</div>
					</LinkWrap>
					<ImgWrap>
						<img draggable="false" src={Logo} alt="Logo" />
					</ImgWrap>
				</FooterContent>
			</FooterWrap>
			<Mark>© DESIGNED AND DEVELOPED BY GOSHAWTY IN 2024</Mark>
		</>
	);
};

const Mark = styled.p`
	font-size: 12px;
	padding-bottom: 25px;
	color: #494949;
`;

const FooterWrap = styled.div`
	background: ${({ theme }) => theme.blackBg};
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const FooterContent = styled.div`
	padding: 0 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: ${({ theme }) => theme.screenWidthSmall};

	@media (max-width: 820px) {
		flex-direction: column;
		justify-content: center;
		padding-bottom: 30px;
	}
`;

const LinkWrap = styled.div`
	display: flex;
	padding: 50px 0;

	> div {
		display: flex;
		flex-direction: column;

		&:nth-of-type(1) {
			align-items: flex-end;
		}
	}

	a {
		font-family: 'Roboto Slab', serif;
		color: ${({ theme }) => theme.whiteText};
		text-decoration: none;
		font-size: 20px;
		margin-top: 7px;
		padding: 3px;
		width: fit-content;
		transition: 0.1s;

		&:hover {
			background: ${(p) => p.theme.red};
			color: ${(p) => p.theme.blackText};
		}

		@media (max-width: 370px) {
			font-size: 18px;
		}

		@media (max-width: 345px) {
			font-size: 16px;
		}
	}
`;

const ImgWrap = styled.div`
	width: 50%;
	max-height: 300px;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@media (max-width: 820px) {
		width: 100%;
	}
`;

const Divider = styled.div`
	height: 180px;
	width: 2px;
	margin: 0 20px;
	background: ${({ theme }) => theme.whiteText};
`;
