import { Language } from '../hooks/useLanguage';
import { Location } from 'react-router-dom';

export const getNewLangRoute = (lang: Language | '', location: Location) => {
	const isMentorshipPage = location.pathname.includes('mentorship');
	const isResultsPage = location.pathname.includes('results');
	const isIndicatorsPage = location.pathname.includes('indicators');
	return `${lang}${
		isMentorshipPage
			? '/mentorship'
			: isResultsPage
			? '/results'
			: isIndicatorsPage
			? '/indicators'
			: ''
	}`;
};

export const scrollToTop = () => {
	window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};

export const openInNewTab = (url: string) => {
	window.open(url, '_blank', 'noreferrer');
};
