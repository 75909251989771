import React, { FC } from 'react';
import Discord from '../../../assets/discord.png';
import styled from 'styled-components';

type ResultBlockProps = { username: string; text: string };

export const ResultBlock: FC<ResultBlockProps> = ({ username, text }) => {
	return (
		<Result>
			<div>
				<img src={Discord} alt="Discord Logo" />
				<p>{username}</p>
			</div>
			<Divider />
			<p>{text}</p>
		</Result>
	);
};

const Divider = styled.div`
	margin: 20px 0;
	height: 2px;
	width: 200px;
	background: ${({ theme }) => theme.whiteText};
`;

const Result = styled.div`
	p {
		max-width: 700px;
		font-size: 15px;
	}

	> div {
		display: flex;
		align-items: center;

		> img {
			width: 28px;
		}

		p {
			font-size: 20px;
			margin-left: 10px;
		}
	}
`;
