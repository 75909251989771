/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Logo from '../../assets/redirectBlack.gif';
import Logo2 from '../../assets/redirectWhite.gif';

type RedirectProps = { link: string; customText?: string };

export const Redirect: FC<RedirectProps> = ({ link, customText }) => {
	const [counter, setCounter] = useState(4);
	const [fadeIn, setFadeIn] = useState(true);
	const [fadeOut, setFadeOut] = useState(false);
	const { matches: darkTheme } = window.matchMedia(
		'(prefers-color-scheme: dark)'
	);

	useEffect(() => {
		counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
	}, [counter]);

	useEffect(() => {
		setTimeout(() => {
			setFadeIn(false);
		}, 300);
	}, []);

	useEffect(() => {
		if (counter === 2) {
			setFadeOut(true);
		}
		if (counter === 0) {
			window.location.replace(link);
		}
	}, [counter]);

	return (
		<TelegramWrapper>
			{fadeOut && <FadeOut />}
			{fadeIn && <FadeIn />}
			<div>
				<h1>
					You are being redirected to <br />
					{customText ? (
						<>{customText}</>
					) : (
						<>
							<span>GoShawty</span> <span>Telegram</span> Channel
						</>
					)}{' '}
					in {counter} seconds.
				</h1>
				<div>
					<img src={darkTheme ? Logo : Logo2} alt="Logo" />
				</div>
			</div>
		</TelegramWrapper>
	);
};

const fadeOut = keyframes`
	0% {opacity: 0;}
	100% {opacity: 1;} 
 `;

const fadeIn = keyframes`
 0% {opacity: 1;}
 100% {opacity: 0;} 
`;

const FadeOut = styled.div`
	position: absolute;
	z-index: 99999999;
	width: 100vw;
	height: 100vh;
	background: white;
	animation: ${fadeOut} 2s;

	@media (prefers-color-scheme: dark) {
		background: #101010;
	}
`;

const FadeIn = styled.div`
	position: absolute;
	z-index: 99999999;
	width: 100vw;
	height: 100vh;
	background: white;
	animation: ${fadeIn} 0.4s;

	@media (prefers-color-scheme: dark) {
		background: #101010;
	}
`;

const TelegramWrapper = styled.div`
	position: absolute;
	z-index: 99999999;
	width: 100vw;
	height: 100vh;
	background: white;
	display: flex;
	align-items: center;
	justify-content: center;

	@media (prefers-color-scheme: dark) {
		background: #101010;

		h1 {
			color: white;
		}
	}

	h1 {
		text-align: center;
		font-size: 30px;

		span {
			font-size: 30px;
			color: red;

			&:nth-of-type(2) {
				color: #21a4d7;
			}
		}

		@media (max-width: 950px) {
			font-size: 20px;

			span {
				font-size: 20px;
			}
		}
	}

	div {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 100vw;

		div {
			margin-top: 25px;
			max-width: 300px;
			height: 75px;

			img {
				animation: ${fadeOut} 2s;
				width: 100%;
			}
		}
	}
`;
