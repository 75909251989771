import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { Slide, ToastContainer } from 'react-toastify';
import { Header } from '../components/Header/Header';
import { GlobalStyle, defaultTheme } from './styles';
import { Footer } from '../components/Footer/Footer';
import 'react-toastify/dist/ReactToastify.css';
import { Popup } from '../components/Popup/Popup';

export default function Root() {
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
	localStorage.setItem('pageOpened', JSON.stringify(new Date()));

	window.addEventListener('beforeunload', function (e) {
		localStorage.removeItem('pageOpened');
		return null;
	});

	return (
		<>
			<GlobalStyle />
			<ThemeProvider theme={defaultTheme}>
				<ToastContainer
					position="top-right"
					transition={Slide}
					autoClose={3000}
					hideProgressBar
					theme="light"
				/>
				<AppWrapper>
					<Header isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
					<Outlet />
					<Footer />
					<Popup isFloaterShown={!isMenuOpen} />
				</AppWrapper>
			</ThemeProvider>
		</>
	);
}

const AppWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;
