import React, { FC, useEffect, useState } from 'react';
import LoadingGif from '../../assets/loading.gif';
import styled from 'styled-components';

type LoadingProps = {
	showLoading: boolean;
};

export const Loading: FC<LoadingProps> = ({ showLoading }) => {
	const [src, setSrc] = useState(LoadingGif);

	useEffect(() => {
		setTimeout(() => {
			setSrc(showLoading ? LoadingGif : '');
		}, 500);
	}, [showLoading]);

	return (
		<LoadingWrapper showLoading={showLoading}>
			<div>
				<img src={src} alt="loading screen" draggable="false" />
			</div>
		</LoadingWrapper>
	);
};

const LoadingWrapper = styled.div<{ showLoading: boolean }>`
	z-index: 1000000;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 120vh;
	width: 100vw;
	background: black;
	transition: 0.3s;

	div {
		img {
			user-select: none;
			margin-top: -200px;
			width: 100%;
		}
	}

	opacity: ${({ showLoading }) => !showLoading && 0};
	visibility: ${({ showLoading }) => !showLoading && 'hidden'};
`;
