import { useLocation } from 'react-router-dom';
import { LANGUAGE_OPTIONS } from '../lang/community';

export type Language = 'ua' | 'ru' | 'en';

export const useLanguage = () => {
	const location = useLocation();
	const splittedList = location.pathname.split('/');
	const language = LANGUAGE_OPTIONS.map(
		(language) => splittedList.includes(language) && language
	).filter((i) => i)[0];

	return (language || 'ua') as Language;
};
