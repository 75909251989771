import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

type OutlinedButtonProps = {
	text: string;
	to: string;
	isWhite?: boolean;
	isSmall?: boolean;
};

export const OutlinedButton: FC<OutlinedButtonProps> = ({
	text,
	to,
	isWhite,
	isSmall,
}) => {
	return (
		<Wrap
			to={to}
			draggable="false"
			target="_blank"
			isWhite={isWhite}
			isSmall={isSmall}
		>
			{text}
		</Wrap>
	);
};

const Wrap = styled(Link)<{ isWhite?: boolean; isSmall?: boolean }>`
	border: 2px solid
		${({ theme, isWhite }) => (isWhite ? theme.whiteText : theme.blackBg)};
	width: ${({ isSmall }) => (isSmall ? '200px' : '250px')};
	height: ${({ isSmall }) => (isSmall ? '50px' : '70px')};
	font-size: ${({ isSmall }) => (isSmall ? '13px' : '16px')};
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${({ theme, isWhite }) => (isWhite ? theme.whiteText : theme.blackBg)};
	text-decoration: none;
	text-transform: uppercase;

	&:hover {
		color: ${({ theme, isWhite }) =>
			isWhite ? theme.blackText : theme.whiteText};
		background-color: ${({ theme, isWhite }) =>
			isWhite ? theme.whiteText : theme.blackText};
	}
`;
