import React, { useEffect, useState } from 'react';
import { useLanguage } from '../../hooks/useLanguage';
import { LANG_TEXT, TITLES } from '../../lang/community';
import { OutlinedButton } from '../OutlinedButton/OutlinedButton';
import { TextBlock } from '../TextBlock/TextBlock';
import styled from 'styled-components';
import { Loading } from '../LoadingScreen/Loading';

export const Mentorship = () => {
	const language = useLanguage();
	const [width, setWidth] = useState(window.innerWidth);
	const updateDimensions = () => {
		setWidth(window.innerWidth);
	};
	useEffect(() => {
		window.addEventListener('resize', updateDimensions);
		return () => window.removeEventListener('resize', updateDimensions);
	}, []);
	const isMobile = width <= 1090;

	const [showLoading, setShowLoading] = useState(true);

	useEffect(() => {
		document.title = TITLES[language].mentorship;
	}, [language]);

	useEffect(() => {
		setTimeout(() => {
			setShowLoading(false);
		}, 2490);
	}, []);

	return (
		<>
			<Loading showLoading={showLoading} />
			<MentorshipWrap usePadding>
				<MentorshipHeading>
					<h1>GOSHAWTY 1-1 MENTORSHIP</h1>
					<Divider />
					<p>{LANG_TEXT[language].mentorship.text1}</p>
					<p>{LANG_TEXT[language].mentorship.text2}</p>
				</MentorshipHeading>
			</MentorshipWrap>
			<MentorshipWrap isWhite>
				<MentorshipList>
					<section>
						<MentorshipListBlock>
							<TextBlock
								heading={LANG_TEXT[language].mentorship.subBlock1.heading}
								text={LANG_TEXT[language].mentorship.subBlock1.text}
							/>
							<TextBlock
								heading={LANG_TEXT[language].mentorship.subBlock2.heading}
								text={LANG_TEXT[language].mentorship.subBlock2.text}
							/>
							<TextBlock
								heading={LANG_TEXT[language].mentorship.subBlock3.heading}
								text={LANG_TEXT[language].mentorship.subBlock3.text}
							/>
							<TextBlock
								heading={LANG_TEXT[language].mentorship.subBlock4.heading}
								text={LANG_TEXT[language].mentorship.subBlock4.text}
								hideDivider
							/>
						</MentorshipListBlock>
						<MentorshipListBlock>
							<TextBlock
								heading={LANG_TEXT[language].mentorship.subBlock5.heading}
								text={LANG_TEXT[language].mentorship.subBlock5.text}
								isReverse={isMobile}
							/>
							<TextBlock
								heading={LANG_TEXT[language].mentorship.subBlock6.heading}
								text={LANG_TEXT[language].mentorship.subBlock6.text}
								isReverse={isMobile}
							/>
							<TextBlock
								heading={LANG_TEXT[language].mentorship.subBlock7.heading}
								text={LANG_TEXT[language].mentorship.subBlock7.text}
								isReverse={isMobile}
							/>
							<TextBlock
								heading={LANG_TEXT[language].mentorship.subBlock8.heading}
								text={LANG_TEXT[language].mentorship.subBlock8.text}
								isReverse={isMobile}
								hideDivider
							/>
						</MentorshipListBlock>
						<PlanText>
							<h1>{LANG_TEXT[language].mentorship.plan}</h1>
							<BigDivider />
						</PlanText>
					</section>
					<BookSection>
						<OutlinedButton
							text={LANG_TEXT[language].mentorship.bookPlace}
							to="https://t.me/goshawtyxx"
						/>
						<h1>$4000</h1>
					</BookSection>
				</MentorshipList>
			</MentorshipWrap>
		</>
	);
};

const MentorshipWrap = styled.div<{
	isWhite?: boolean;
	usePadding?: boolean;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding-top: ${({ usePadding }) => usePadding && '130px'};
	background: ${(p) => (p.isWhite ? p.theme.whiteBg : p.theme.blackBg)};

	@media (max-width: 950px) {
		padding-top: ${({ usePadding }) => usePadding && '100px'};
	}
`;

const MentorshipContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: ${(p) => p.theme.screenWidthSmall};
	padding: 20px 15px;
`;

const MentorshipHeading = styled(MentorshipContent)`
	min-height: calc(100vh - 100px);
	padding-bottom: 40px;

	@media (max-width: 910px) {
		text-align: left;
		font-size: 50px;
		align-items: flex-start;
	}

	h1,
	p {
		color: ${({ theme }) => theme.whiteText};
	}

	h1 {
		margin-top: 80px;
		text-align: center;
		font-size: 87px;

		@media (max-width: 1300px) {
			font-size: 70px;
		}

		@media (max-width: 1050px) {
			font-size: 60px;
		}

		@media (max-width: 910px) {
			margin-top: 10px;
			text-align: left;
			font-size: 50px;
		}

		@media (max-width: 410px) {
			text-align: left;
			font-size: 40px;
		}
	}

	p {
		@media (max-width: 910px) {
			font-size: 19px;
		}

		@media (max-width: 410px) {
			font-size: 16px;
		}

		&:nth-of-type(2) {
			margin-top: 24px;

			@media (max-width: 610px) {
				padding-bottom: 30px;
			}
		}
	}
`;

const Divider = styled.div`
	height: 2px;
	width: 50%;
	background: ${({ theme }) => theme.whiteText};
	margin: 50px 0;

	@media (max-width: 910px) {
		margin: 40px 0;
	}
`;

const BigDivider = styled.div`
	height: 2px;
	width: 400px;
	background: ${({ theme }) => theme.blackText};
	margin: 30px 0;
`;

const PlanText = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transform: rotate(90deg) translateX(180px);
	width: 300px;
	height: fit-content;

	h1 {
		font-size: 80px;
		text-align: center;
	}

	@media (max-width: 1090px) {
		display: none;
		visibility: hidden;
	}
`;

const MentorshipList = styled(MentorshipContent)`
	align-items: flex-start;
	margin-top: 30px;
	min-height: calc(100vh - 100px);

	> section {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		@media (max-width: 785px) {
			flex-direction: column;
		}
	}
`;

const MentorshipListBlock = styled.div`
	&:nth-of-type(2) {
		margin-left: 100px;

		@media (max-width: 785px) {
			margin-top: 50px;
		}
	}

	> div {
		margin-top: 20px;

		&:nth-of-type(1) {
			margin-top: 0;
		}
	}
`;

const BookSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-top: 100px;
	margin-bottom: 60px;

	a {
		margin-right: 12px;

		@media (max-width: 470px) {
			margin-right: 0;
		}
	}

	h1 {
		font-family: 'Roboto Mono', serif;
		font-weight: 300;
		font-size: 50px;
		margin-left: 12px;

		@media (max-width: 470px) {
			margin-left: 0;
			margin-bottom: 20px;
		}
	}

	@media (max-width: 470px) {
		margin-top: 60px;
		flex-direction: column-reverse;
	}
`;
