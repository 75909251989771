export const TELEGRAM_LINKS = {
	goshawty: 'https://t.me/+eLLLEDmAoyZjMzYy',
	goshawtyDiscord: 'https://t.me/+uVfum4yZq8cyMGEy',
	dxdy0x: 'https://t.me/+By1SsyYadAs2NDM6',
	cage: 'https://t.me/+Ki9a3T-ey89mNGUy',
	vladikk317: 'https://t.me/+mBpkdmz1fjljYjIy',
	bu1bazawr: 'https://t.me/+YDPYWDxXMlNkYTIy',
	vicione: 'https://t.me/+C_fZCRPKfzEzMDli',
	yankie: 'https://t.me/+JbnF8Gf4OxRlZTMy',
	sleg: 'https://t.me/+LgIO_8eOWao4MTRi',
};
