import React, { FC } from 'react';
import styled from 'styled-components';

type TextBlockProps = {
	heading: string;
	text: string;
	hideDivider?: boolean;
	isReverse?: boolean;
	isWhite?: boolean;
};

export const TextBlock: FC<TextBlockProps> = ({
	heading,
	text,
	hideDivider,
	isReverse,
	isWhite,
}) => {
	return (
		<Block isReverse={isReverse} isWhite={isWhite}>
			<h1>{heading}</h1>
			<p>{text}</p>
			{!hideDivider && <Divider isWhite={isWhite} />}
		</Block>
	);
};

const Divider = styled.div<{ isWhite?: boolean }>`
	margin-top: 32px;
	height: 2px;
	width: 60%;
	background: ${({ theme, isWhite }) =>
		isWhite ? theme.whiteText : theme.blackText};
`;

const Block = styled.div<{ isReverse?: boolean; isWhite?: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: ${({ isReverse }) => (isReverse ? 'flex-end' : 'flex-start')};
	text-align: ${({ isReverse }) => (isReverse ? 'right' : 'left')};
	color: ${({ theme, isWhite }) =>
		isWhite ? theme.whiteText : theme.blackText};

	h1 {
		font-size: 50px;

		@media (max-width: 540px) {
			font-size: 45px;
		}

		@media (max-width: 490px) {
			font-size: 40px;
		}

		@media (max-width: 440px) {
			font-size: 35px;
		}

		@media (max-width: 380px) {
			font-size: 30px;
		}
	}
	p {
		font-size: 20px;
		max-width: 400px;

		@media (max-width: 540px) {
			font-size: 19px;
		}

		@media (max-width: 490px) {
			font-size: 18px;
		}

		@media (max-width: 440px) {
			font-size: 17px;
		}

		@media (max-width: 380px) {
			font-size: 16px;
		}
	}
`;
