import React, { FC } from 'react';

import styled from 'styled-components';

type OutlinedButtonProps = {
	text: string;
	onClick: () => void;
	isWhite?: boolean;
};

export const OutlinedButton2: FC<OutlinedButtonProps> = ({
	text,
	onClick,
	isWhite,
}) => {
	return (
		<Wrap onClick={onClick} isWhite={isWhite}>
			{text}
		</Wrap>
	);
};

const Wrap = styled.div<{ isWhite?: boolean }>`
	border: 2px solid
		${({ theme, isWhite }) => (isWhite ? theme.whiteText : theme.blackBg)};
	width: 250px;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${({ theme, isWhite }) => (isWhite ? theme.whiteText : theme.blackBg)};
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;

	&:hover {
		color: ${({ theme, isWhite }) =>
			isWhite ? theme.blackText : theme.whiteText};
		background-color: ${({ theme, isWhite }) =>
			isWhite ? theme.whiteText : theme.blackText};
	}
`;
