import { createGlobalStyle } from 'styled-components';

export const defaultTheme = {
	whiteText: '#e4e4e4',
	whiteBg: '#ffffff',
	blackText: '#000000',
	blackBg: '#000000',
	red: '#C82626',
	redDark: '#7b1a1a',
	screenWidth: '1440px',
	screenWidthSmall: '1300px',
};

export const GlobalStyle = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px;
    
  }

  .Toastify__toast {
    border-radius: 0px; 
  }

  
  ::-webkit-scrollbar {
    display: none;
  }
  
  body {
	font-family: 'Roboto Mono', serif;
  min-height: 100vh;
  background: #000;
  }
  
  p {
    font-size: 20px;
    font-weight: 300;
  }

  a{
    user-select: none;
    cursor: pointer;
  }

  h1,h2,h3 {
	font-family: 'Roboto Slab', serif;
  }

  h1, h2, p{
    &::-moz-selection {
        color: #7b1a1a;
        background: #e4e4e4;
    }

    &::selection {
      color: #7b1a1a;
        background: #e4e4e4;
    }
  }

  img{
    user-select: none;
  }
`;
