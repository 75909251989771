import React, { useEffect, useState } from 'react';
import Desk1 from '../../assets/desk1.png';
import Mob1 from '../../assets/mob1.png';
import Desk2 from '../../assets/desk2.png';
import Desk3 from '../../assets/desk3.png';
import Mob3 from '../../assets/mob3.png';
import styled from 'styled-components';
import { TextBlock } from '../TextBlock/TextBlock';
import { LANG_TEXT, TITLES } from '../../lang/community';
import { useLanguage } from '../../hooks/useLanguage';
import { OutlinedButton } from '../OutlinedButton/OutlinedButton';
import { Loading } from '../LoadingScreen/Loading';

export const Community = () => {
	const language = useLanguage();
	const [showLoading, setShowLoading] = useState(true);
	// const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		document.title = TITLES[language].community;
	}, [language]);

	useEffect(() => {
		setTimeout(() => {
			setShowLoading(false);
		}, 2490);
	}, []);

	return (
		<>
			<Loading showLoading={showLoading} />
			{/* <Modal isOpen={showModal} close={() => setShowModal(false)} /> */}
			<CommunityWrapper usePadding>
				<CommunityContent>
					<GreetingsBlock>
						<ImgWrap>
							<img draggable="false" src={Desk1} alt="Gold" />
							<img draggable="false" src={Mob1} alt="Gold" />
						</ImgWrap>
						<h1>GOSHAWTY. LIVE. SERVER.</h1>
						<Divider />
						<p>{LANG_TEXT[language].community.entry}</p>
					</GreetingsBlock>
				</CommunityContent>
			</CommunityWrapper>
			<CommunityWrapper isWhite>
				<CommunityContent>
					<InfoBlock>
						<div>
							<TextBlock
								heading={LANG_TEXT[language].community.block1.subBlock1.heading}
								text={LANG_TEXT[language].community.block1.subBlock1.text}
							/>
							<TextBlock
								heading={LANG_TEXT[language].community.block1.subBlock2.heading}
								text={LANG_TEXT[language].community.block1.subBlock2.text}
							/>
							<TextBlock
								heading={LANG_TEXT[language].community.block1.subBlock3.heading}
								text={LANG_TEXT[language].community.block1.subBlock3.text}
								hideDivider
							/>
						</div>
						<ImgWrapHalf>
							<img draggable="false" src={Desk2} alt="Gold" />
							<img draggable="false" src={Desk2} alt="Gold" />
						</ImgWrapHalf>
					</InfoBlock>
				</CommunityContent>
			</CommunityWrapper>
			<CommunityWrapper>
				<CommunityContent>
					<InfoBlock2>
						<ImgWrapHalf>
							<img draggable="false" src={Desk3} alt="Gold" />
							<img draggable="false" src={Mob3} alt="Gold" />
						</ImgWrapHalf>
						<div>
							<TextBlock
								heading={LANG_TEXT[language].community.block2.subBlock1.heading}
								text={LANG_TEXT[language].community.block2.subBlock1.text}
								isReverse
								isWhite
							/>
							<TextBlock
								heading={LANG_TEXT[language].community.block2.subBlock2.heading}
								text={LANG_TEXT[language].community.block2.subBlock2.text}
								isReverse
								isWhite
							/>
							<TextBlock
								heading={LANG_TEXT[language].community.block2.subBlock3.heading}
								text={LANG_TEXT[language].community.block2.subBlock3.text}
								isReverse
								isWhite
								hideDivider
							/>
						</div>
					</InfoBlock2>
				</CommunityContent>
			</CommunityWrapper>
			<CommunityWrapper isWhite>
				<CommunityContent>
					<InfoBlock3>
						<h1>{LANG_TEXT[language].community.block3.heading}</h1>
						{/* <p>{LANG_TEXT[language].community.block3.text1}</p>
						<p>{LANG_TEXT[language].community.block3.text2}</p> */}
						<p>{LANG_TEXT[language].community.block3.text3}</p>
						<div>
							<OutlinedButton
								text={LANG_TEXT[language].community.block3.buy}
								to="https://t.me/+glAzK0Bj8Cg1YTMy"
							/>
						</div>
					</InfoBlock3>
				</CommunityContent>
			</CommunityWrapper>
		</>
	);
};

const CommunityWrapper = styled.div<{
	isWhite?: boolean;
	usePadding?: boolean;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding-top: ${({ usePadding }) => usePadding && '130px'};
	background: ${(p) => (p.isWhite ? p.theme.whiteBg : p.theme.blackBg)};

	@media (max-width: 950px) {
		padding-top: ${({ usePadding }) => usePadding && '100px'};
	}
`;

const CommunityContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: ${(p) => p.theme.screenWidthSmall};
	padding: 20px 15px;
`;

const Divider = styled.div`
	height: 2px;
	width: 50%;
	background: ${({ theme }) => theme.whiteText};
`;

const GreetingsBlock = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	color: ${({ theme }) => theme.whiteText};
	text-align: center;
	background: ${(p) => p.theme.blackBg};
	padding: 20px 1px 100px;

	@media (max-width: 950px) {
		padding: 5px 1px 90px;
	}

	@media (max-width: 780px) {
		align-items: flex-start;
		text-align: left;
	}

	h1 {
		margin: 45px 0 40px;
		font-size: 75px;

		@media (max-width: 1150px) {
			font-size: 70px;
		}

		@media (max-width: 1010px) {
			font-size: 55px;
		}

		@media (max-width: 1010px) {
			font-size: 50px;
		}

		@media (max-width: 380px) {
			font-size: 50px;
		}

		@media (max-width: 350px) {
			font-size: 40px;
		}
	}

	p {
		margin-top: 40px;
		font-size: 20px;

		@media (max-width: 500px) {
			font-size: 19px;
		}
	}

	img {
		&:nth-of-type(1) {
			@media (max-width: 950px) {
				display: none;
				visibility: hidden;
			}
		}

		&:nth-of-type(2) {
			@media (min-width: 951px) {
				display: none;
				visibility: hidden;
			}
		}
	}
`;

const ImgWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;

	img {
		width: 100%;
	}
`;

const ImgWrapHalf = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50%;

	@media (max-width: 1100px) {
		margin-bottom: 20px;
		width: 100%;
	}

	img {
		width: 100%;
	}
`;

const InfoBlock = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: ${(p) => p.theme.whiteBg};
	margin-top: 100px;
	padding: 0 1px 100px;

	@media (max-width: 1100px) {
		flex-direction: column-reverse;
		align-items: flex-start;
		width: 100%;
		margin-top: 50px;
	}

	img {
		@media (min-width: 500px) and (max-width: 1100px) {
			display: none;
			visibility: hidden;
		}

		&:nth-of-type(1) {
			@media (max-width: 1100px) {
				display: none;
				visibility: hidden;
			}
		}

		&:nth-of-type(2) {
			@media (min-width: 1101px) {
				display: none;
				visibility: hidden;
			}
		}
	}

	> div {
		> div {
			margin-top: 20px;

			&:nth-of-type(1) {
				margin-top: 0;
			}
		}
	}
`;

const InfoBlock2 = styled(InfoBlock)`
	background: ${(p) => p.theme.blackBg};

	@media (max-width: 1100px) {
		flex-direction: column;
		align-items: flex-end;
	}
`;

const InfoBlock3 = styled.div`
	margin-top: 80px;

	h1 {
		font-size: 50px;

		@media (max-width: 400px) {
			font-size: 40px;
		}

		@media (max-width: 322px) {
			font-size: 35px;
		}
	}
	p {
		margin-top: 24px;

		&:nth-of-type(4),
		&:nth-of-type(5) {
			font-style: italic;
		}
	}

	div {
		margin-top: 50px;
		margin-bottom: 40px;
		display: flex;

		@media (max-width: 560px) {
			flex-direction: column;
		}

		@media (max-width: 450px) {
			align-items: center;
		}

		> div {
			margin: 0;
		}

		a {
			@media (max-width: 560px) {
				margin-left: 0px;
				margin-top: 24px;
			}
		}
	}
`;
