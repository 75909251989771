export const LANGUAGE_OPTIONS = ['ua', 'ru', 'en'];

export const LANG_TEXT = {
	ua: {
		text1: 'Українська',
		header: {
			community: 'GS CLUB',
			mentorship: 'МЕНТОРШИП',
			indicators: 'ІНДИКАТОРИ',
			contact: 'ЗВʼЯЗОК',
			results: 'РЕЗУЛЬТАТИ',
		},
		community: {
			entry:
				'Сьогодні на ринку існує купа комюніті, де з вірогідністю 99% є хоча б одна із таких двох проблем: або слабке і неякісне, або токсичне оточення. А часто ці проблеми йдуть пліч-о-пліч. Тому ціллю створення GoShawty Live серверу було - зібрати в одному місці серйозно налаштованих людей, з яких 20-30% являється/стане стабільними фандет-трейдерами (зараз уже >40%), що в свою чергу гарантує максимально якісне і сильне оточення як для всіх учасників, так і для мене особисто.',
			block1: {
				subBlock1: {
					heading: '16 ГІЛОК',
					text: `та чатів з аналітикою, корисними
					матеріалами та стрімами, без лишнього`,
				},
				subBlock2: {
					heading: 'LIVE-TRADING',
					text: `FX, золота та індексів разом з GoShawty та командою`,
				},
				subBlock3: {
					heading: 'BREAKFAST CALLS',
					text: `щодня, з аналітикою та розбором плану на день`,
				},
			},
			block2: {
				subBlock1: {
					heading: 'КОМАНДА',
					text: `яка ділиться своїми думками та аналітикою, а також постійно проводить стріми на різні теми`,
				},
				subBlock2: {
					heading: 'БАЗА',
					text: `з записами стрімів та всім технічним матеріалом, без води, 100% того, що ми реально використовуємо в своїй торгівлі`,
				},
				subBlock3: {
					heading: '>40%',
					text: `учасників серверу являються фандет-трейдерами, що говорить про сильне оточення`,
				},
			},
			block3: {
				heading: 'Як потрапити на сервер?',
				text1: `Придбати один із варіантів підписок:`,
				text2: `Місячна підписка - $49/місяць.`,
				text3: `LifeTime підписка - $525. Оплата 1 раз і доступ залишається назавжди.`,
				buy: 'ПОТРАПИТИ НА СЕРВЕР',
			},
		},
		mentorship: {
			text1: `Трейдинг - не тільки про аналітику, а про правильне прийняття рішень,
			грамотний та переможний настрій всередині вас і що важливо —
			систематичний підхід. Зараз я знаю, що можу дати достатньо інформації
			по розумінню ринку та підходу до трейдингу в цілому. Це підкріплено
			моїми особистими результатами, тим, як я думаю та транслюю мої думки
			публічно на каналі та щоденних прямих ефірах, ну і звичайно, пройденим
			мною особисто шляхом: від купи злитих аккаунтів до закриття нових
			аккаунтів без стопів, від втрати всіх аккаунтів в 1 день до
			повноцінного камбеку за кілька тижнів і від того, хто починав йти сам
			з завʼязаними очима, і до того, в кого очі хоч трохи відкрились.`,
			text2: `Робота ведеться індивідуально 1 на 1 зі мною і до результату "під
			ключ" — це може бути як отримання вами лайв-аккаунту і перших виплат,
			так і просто стабільність і віра в ті позиції, які ви відкриваєте.
			Робота повністю без часових рамок, до результату.`,
			subBlock1: {
				heading: 'XAU & INDICES',
				text: `Повне пропрацювання технічних складових торгівлі золота та індексів.`,
			},
			subBlock2: {
				heading: 'HOMEWORK',
				text: `Виконання вами і перевірка мною практичних завдань.`,
			},
			subBlock3: {
				heading: 'BACKTEST',
				text: `Правильний і якісний бектест.`,
			},
			subBlock4: {
				heading: 'SYSTEM',
				text: `Побудова і систематичний розбір вашого торгового журналу і ТС.`,
			},
			subBlock5: {
				heading: '0 EMOTIONS',
				text: `Робота з емоційним впливом і прийняттям рішень.`,
			},
			subBlock6: {
				heading: 'GUIDANCE',
				text: `Супровід на етапі проходження челенджу і до перших виплат в лайві.`,
			},
			subBlock7: {
				heading: 'MENTALITY',
				text: `Winning mentality і моє бачення.`,
			},
			subBlock8: {
				heading: 'DISCORD',
				text: `Пожиттєвий доступ в закритий сервер та до матеріалів в Notion.`,
			},
			bookPlace: 'розпочати',
			plan: 'ПЛАН РОБОТИ',
		},
		results: {
			heading: 'ВІДГУКИ І РЕЗУЛЬТАТИ',
			info: 'ВІДГУКИ НЕ ПЕРЕКЛАДАЮТЬСЯ ДЛЯ ЗБЕРЕЖЕННЯ КОНТЕКСТУ.',
			info2: '>40% НАШИХ ТРЕЙДЕРІВ ЯВЛЯЮТЬСЯ ФАНДЕТ.',
			next: 'СТАТИ НАСТУПНИМ',
		},
		modal: {
			or: 'АБО',
			name: 'ІМʼЯ (ЗА БАЖАННЯМ)',
			contact: 'TELEGRAM / НОМЕР ТЕЛЕФОНУ',
			month: '3 МІСЯЦІ',
			send: 'НАДІСЛАТИ',
			support: 'НАПИСАТИ В ПІДТРИМКУ',
			thanks: 'МИ ОТРИМАЛИ ВАШ ЗАПИТ І ЗВʼЯЖЕМОСЬ З ВАМИ.',
		},
		affiliate: {
			step1: {
				p1: 'РЕЄСТРУЙСЯ ЗА НАШИМИ',
				p2: 'ПОСИЛАННЯМИ',
			},
			step2: {
				p1: 'КУПЛЯЙ СВІЙ ПЕРШИЙ',
				p2: 'ПРОП-АККАУНТ',
			},
			step3: {
				p1: 'ПИШИ НАМ В ',
				p2: 'ПІДТРИМКУ',
			},
			step4: {
				p1: 'ОТРИМАЙ ЗНИЖКУ АБО',
				p2: 'КЕШБЕК З ПОКУПКИ',
			},
			cashback: '4% Кешбек',
			discount: '5% Знижка',
			discount2: '10% Знижка',
			note: `Знижка або кешбек діє на першу покупку з нового зареєстрованого
			аккаунту за нашим посиланням. Для повторної знижки/кешбеку -
			зареєструйте новий аккаунт за посиланням, зробіть з нього покупку і
			напишіть у підтримку. Знижка дається одразу, кешбек після
			affiliate-виплати нам.`,
		},
		indicators: {
			heading: 'GOSHAWTY INDICATORS',
			subheading:
				'ВСІ ІНДИКАТОРИ РОЗРОБЛЕНІ І ВИКОРИСТОВУЮТЬСЯ ОСОБИСТО МНОЮ ЩОДНЯ.',
			add: 'Додати індикатор',
			guide: 'Відео-інструкція',
			list: [
				{
					id: 1,
					name: 'GOSHAWTY LOT SIZE CALCULATOR',
					shortInfo: 'КАЛЬКУЛЯТОР ЛОТАЖУ ДЛЯ ФОРЕКСУ, ІНДЕКСІВ ТА МЕТАЛІВ',
					longInfo:
						'Індикатор дає можливість моментально прорахувати лотаж на будь-яку пару, поставивши тільки стоп і точку входу.',
					scriptURL:
						'https://www.tradingview.com/script/ig3zt1fE-GoShawty-Lot-Size-Calculator/',
					guideURL: 'https://youtu.be/kYldk9bwP6E?t=274',
				},
				{
					id: 2,
					name: 'GOSHAWTY FOREX SESSIONS',
					shortInfo: 'Індикатор FX Сесій + Фрактали',
					longInfo:
						'Індикатор підтримує основні торгові сесії: Азія, Франкфурт, Лондон, Нью Йорк. Також показує фрактали.',
					scriptURL:
						'https://www.tradingview.com/script/lEpBLbbZ-GoShawty-Sessions/',
					guideURL: 'https://youtu.be/kYldk9bwP6E?t=20',
				},
				{
					id: 3,
					name: 'GOSHAWTY ULTIMATE',
					shortInfo: 'Індикатор Всіх Сесій + Фрактали + Ліквідність',
					longInfo:
						'Індикатор підтримує всі торгові сесії: Азія, Франкфурт, Лондон, Нью Йорк АМ та ПМ сесію. Показує фрактали та PDWMHL.',
					scriptURL:
						'https://www.tradingview.com/script/sN2kjiEw-GoShawty-Ultimate/',
					guideURL: 'https://youtu.be/kYldk9bwP6E?t=149',
				},
				{
					id: 4,
					name: 'GOSHAWTY INDEX SESSIONS',
					shortInfo:
						'Індикатор Індекс Сесій + Фрактали + Silver Bullet + Macros',
					longInfo:
						'Індикатор підтримує торгові сесії індексів: Лондон, Нью Йорк АМ та ПМ сесію. Показує фрактали, макро та Silver Bullet таймінги.',
					scriptURL:
						'https://www.tradingview.com/script/PdyT22uI-GoShawty-Index-Sessions/',
					guideURL: 'https://youtu.be/kYldk9bwP6E?t=47',
				},
				{
					id: 5,
					name: 'GOSHAWTY RANGE TRACKER',
					shortInfo: 'Індикатор 4-годинних ренджів',
					longInfo:
						'Індикатор показує 4-годинні ренджі, також підтримує СМЕ таймінги початку формування свічок.',
					scriptURL:
						'https://www.tradingview.com/script/oEXtoa8e-GoShawty-Range-Tracker/',
				},
				{
					id: 6,
					name: 'GOSHAWTY WATERMARK',
					shortInfo: 'Вотермарка на 2 ряди',
					longInfo:
						'Просто вотермарка на 2 ряди, щоб в 2-ому ряду можна було вставити рекламу.',
					scriptURL:
						'https://www.tradingview.com/script/C1P3eXs3-GoShawty-Watermark/',
				},
			],
		},
	},
	ru: {
		text1: 'Русский',
		header: {
			community: 'GS CLUB',
			mentorship: 'МЕНТОРШИП',
			indicators: 'ИНДИКАТОРЫ',
			contact: 'СВЯЗЬ',
			results: 'РЕЗУЛЬТАТЫ',
		},
		community: {
			entry:
				'Сегодня на рынке существует множество комьюнити, где с вероятностью 99% есть хотя бы одна из двух таких проблем: либо слабое и некачественное сообщество, либо токсичная атмосфера. А часто эти проблемы идут бок о бок. Поэтому цель создания сервера GoShawty Live была в том, чтобы собрать в одном месте серьезно настроенных людей, из которых 20-30% являются/станут стабильными фандет-трейдерами (сейчас уже >40%), что в свою очередь гарантирует максимально качественное и сильное рабочее окружение как для всех участников, так и для меня лично.',
			block1: {
				subBlock1: {
					heading: '16 ВЕТОК',
					text: `и чатов с аналитикой, полезными
					материалами и стримами, без лишнего`,
				},
				subBlock2: {
					heading: 'LIVE-TRADING',
					text: `FX, золота и индексов вместе з GoShawty и командой`,
				},
				subBlock3: {
					heading: 'BREAKFAST CALLS',
					text: `ежедневно, с аналитикой и разбором плана на день`,
				},
			},
			block2: {
				subBlock1: {
					heading: 'КОМАНДА',
					text: `которая делится своими мыслями и аналитикой, а также постоянно проводит стримы на разные темы`,
				},
				subBlock2: {
					heading: 'БАЗА',
					text: `с записями стримов и всем техническим материалом, без воды, 100% того, что мы реально используем в своей торговле`,
				},
				subBlock3: {
					heading: '>40%',
					text: `участников сервера являются фандет-трейдерами, что говорит о сильном окружении`,
				},
			},

			block3: {
				heading: 'Как попасть на сервер?',
				text1: `Приобрести один из вариантов подписок:`,
				text2: `Месячная подписка - $49/месяц.`,
				text3: `LifeTime подписка – $525. Оплата 1 раз и доступ остается навсегда.`,
				buy: 'ПОПАСТЬ НА СЕРВЕР',
			},
		},
		mentorship: {
			text1: `Трейдинг – не только об аналитике, а о правильном принятии решений,
			грамотном и победном настрое внутри вас и что важно -
			систематическом подходе. Сейчас я знаю, что могу дать достаточно информации
			по пониманию рынка и подходу к трейдингу в целом. Это подкреплено
			моими личными результатами, тем, как я думаю и транслирую мои мысли
			публично на канале и ежедневных прямых эфирах, ну и конечно, пройденным
			мной лично путем: от кучи слитых аккаунтов до закрытия новых
			аккаунтов без стопов, от потери всех аккаунтов в 1 день до
			полноценного камбека за несколько недель и от того, кто начинал идти сам
			с завязанными глазами, и к тому, у кого глаза хоть чуть-чуть открылись.`,
			text2: `Работа ведется индивидуально 1 на 1 со мной и к результату "под
			ключ" — это может быть как получение вами лайв-аккаунта и первых выплат,
			так и просто стабильность и вера в те позиции, которые вы открываете.
			Работа полностью без временных рамок до результата.`,
			subBlock1: {
				heading: 'XAU & INDICES',
				text: `Полная проработка технических составляющих торговли золота и индексов.`,
			},
			subBlock2: {
				heading: 'HOMEWORK',
				text: `Выполнение вами и проверка мною практических заданий.`,
			},
			subBlock3: {
				heading: 'BACKTEST',
				text: `Правильный и качественный бектест.`,
			},
			subBlock4: {
				heading: 'SYSTEM',
				text: `Построение и систематический разбор вашего журнала и ТС.`,
			},
			subBlock5: {
				heading: '0 EMOTIONS',
				text: `Работа с эмоциональным влиянием и принятием решений.`,
			},
			subBlock6: {
				heading: 'GUIDANCE',
				text: `Сопровождение на этапе прохождения челленджа и к первым выплатам в лайве.`,
			},
			subBlock7: {
				heading: 'MENTALITY',
				text: `Winning mentality и мое видение.`,
			},
			subBlock8: {
				heading: 'DISCORD',
				text: `Пожизненный доступ в закрытый сервер и к материалам в Notion.`,
			},
			bookPlace: 'начать',
			plan: 'ПЛАН РАБОТЫ',
		},
		results: {
			heading: 'ОТЗЫВЫ И РЕЗУЛЬТАТЫ',
			info: 'ОТЗЫВЫ НЕ ПЕРЕВОДЯТСЯ ДЛЯ СОХРАНЕНИЯ КОНТЕКСТА.',
			info2: '>40% НАШИХ ТРЕЙДЕРОВ ЯВЛЯЮТСЯ ФАНДЕТ.',
			next: 'СТАТЬ СЛЕДУЮЩИМ',
		},
		modal: {
			or: 'ИЛИ',
			name: 'ИМЯ (ПО ЖЕЛАНИЮ)',
			contact: 'TELEGRAM / НОМЕР ТЕЛЕФОНА',
			month: '3 МЕСЯЦА',
			send: 'ОТПРАВИТЬ',
			support: 'НАПИСАТЬ В ПОДДЕРЖКУ',
			thanks: 'МЫ ПОЛУЧИЛИ ВАШ ЗАПРОС И СВЯЖЕМСЯ С ВАМИ.',
		},
		affiliate: {
			step1: {
				p1: 'РЕГИСТРИРУЙСЯ ПО НАШИМ',
				p2: 'ССЫЛКАМ',
			},
			step2: {
				p1: 'ПОКУПАЙ СВОЙ ПЕРВЫЙ',
				p2: 'ПРОП-АККАУНТ',
			},
			step3: {
				p1: 'ПИШИ НАМ В ',
				p2: 'ПОДДЕРЖКУ',
			},
			step4: {
				p1: 'ПОЛУЧИ СКИДКУ ИЛИ',
				p2: 'КЕШБЭК С ПОКУПКИ',
			},
			cashback: '4% Кешбэк',
			discount: '5% Скидка',
			discount2: '10% Скидка',
			note: `Скидка или кeшбэк действует на первую покупку с нового зарегистрированного
			аккаунта по нашей ссылке. Для повторной скидки/кeшбэка -
			зарегистрируйте новый аккаунт по ссылке, сделайте с него покупку и
			напишите в поддержку. Скидка дается сразу, кeшбэк после
			affiliate-выплаты нам.`,
		},
		indicators: {
			heading: 'GOSHAWTY INDICATORS',
			subheading:
				'ВСЕ ИНДИКАТОРЫ РАЗРАБОТАНЫ И ИСПОЛЬЗУЮТСЯ ЛИЧНО МНОЙ КАЖДЫЙ ДЕНЬ.',
			add: 'Добавить индикатор',
			guide: 'Видео-инструкция',
			list: [
				{
					id: 1,
					name: 'GOSHAWTY LOT SIZE CALCULATOR',
					shortInfo: 'КАЛЬКУЛЯТОР ЛОТАЖА ДЛЯ ФОРЕКСА, ИНДЕКСОВ И МЕТАЛЛОВ',
					longInfo:
						'Индикатор позволяет моментально просчитать лотаж на любую пару, поставив только стоп и точку входа.',
					scriptURL:
						'https://www.tradingview.com/script/ig3zt1fE-GoShawty-Lot-Size-Calculator/',
					guideURL: 'https://youtu.be/kYldk9bwP6E?t=274',
				},
				{
					id: 2,
					name: 'GOSHAWTY FOREX SESSIONS',
					shortInfo: 'Индикатор FX Сессий + Фракталы',
					longInfo:
						'Индикатор поддерживает основные торговые сессии: Азия, Франкфурт, Лондон, Нью-Йорк. Также показывает фракталы.',
					scriptURL:
						'https://www.tradingview.com/script/lEpBLbbZ-GoShawty-Sessions/',
					guideURL: 'https://youtu.be/kYldk9bwP6E?t=20',
				},
				{
					id: 3,
					name: 'GOSHAWTY ULTIMATE',
					shortInfo: 'Индикатор Всех Сессий + Фракталы + Ликвидность',
					longInfo:
						'Индикатор поддерживает все торговые сессии Азия, Франкфурт, Лондон, Нью-Йорк АМ и ПМ сессию. Показывает фракталы и PDWMHL.',
					scriptURL:
						'https://www.tradingview.com/script/sN2kjiEw-GoShawty-Ultimate/',
					guideURL: 'https://youtu.be/kYldk9bwP6E?t=149',
				},
				{
					id: 4,
					name: 'GOSHAWTY INDEX SESSIONS',
					shortInfo:
						'Индикатор Индекс Сессий + Фракталы + Silver Bullet + Macros',
					longInfo:
						'Индикатор поддерживает торговые сессии индексов: Лондон, Нью-Йорк и ПМ сессию. Показывает фракталы, макро и Silver Bullet тайминги.',
					scriptURL:
						'https://www.tradingview.com/script/PdyT22uI-GoShawty-Index-Sessions/',
					guideURL: 'https://youtu.be/kYldk9bwP6E?t=47',
				},
				{
					id: 5,
					name: 'GOSHAWTY RANGE TRACKER',
					shortInfo: 'Индикатор 4-часовых ренджей',
					longInfo:
						'Индикатор показывает 4-часовые рэнджи, также поддерживает СМE тайминги начала формирования свеч.',
					scriptURL:
						'https://www.tradingview.com/script/oEXtoa8e-GoShawty-Range-Tracker/',
				},
				{
					id: 6,
					name: 'GOSHAWTY WATERMARK',
					shortInfo: 'Вотермарка на 2 ряда',
					longInfo:
						'Просто вотермарка на 2 ряда, чтобы во 2-м ряду можно было вставить рекламу.',
					scriptURL:
						'https://www.tradingview.com/script/C1P3eXs3-GoShawty-Watermark/',
				},
			],
		},
	},
	en: {
		text1: 'English',
		header: {
			community: 'GS CLUB',
			mentorship: 'MENTORSHIP',
			indicators: 'INDICATORS',
			contact: 'CONTACT',
			results: 'RESULTS',
		},
		community: {
			entry: `There are a lot of communities on the market today that have at least one of the following two problems with a 99% probability: either weak and poor quality, or a toxic environment. And often these problems go hand in hand. Therefore, the goal of creating the GoShawty Live server was to gather serious-minded people in one place, of which 20-30% are/will become stable funded traders (now it's already >40%), which in turn guarantees the highest quality and strongest environment for all participants, as well as for me personally .`,
			block1: {
				subBlock1: {
					heading: '16 ROOMS',
					text: `and chats with analytics, useful
					materials and streams, without unnecessary stuff`,
				},
				subBlock2: {
					heading: 'LIVE-TRADING',
					text: `FX, gold and indices together with GoShawty and the Team`,
				},
				subBlock3: {
					heading: 'BREAKFAST CALLS',
					text: `daily, with analytics and analysis of the plan for the day`,
				},
			},
			block2: {
				subBlock1: {
					heading: 'TEAM',
					text: `who shares their thoughts and analytics and regularly streams on various topics`,
				},
				subBlock2: {
					heading: 'BASE',
					text: `with stream recordings and all technical stuff, no water, 100% of what we actually use in our trading systems`,
				},
				subBlock3: {
					heading: '>40%',
					text: `of server's members are funded trades, which is an indicator of strong surrounding`,
				},
			},
			block3: {
				heading: 'How to get access to the server?',
				text1: `Purchase one of the subscription options:`,
				text2: `Monthly subscription - $49/month.`,
				text3: `LifeTime subscription - $525. Pay 1 time and access remains forever.`,
				buy: 'GET ACCESS TO THE SERVER',
			},
		},
		mentorship: {
			text1: `Trading is not only about analytics, but about correct decision-making,
			competent and victorious mood inside you and what is important —
			systematic approach. Now I know I can give enough information
			on understanding the market and the approach to trading in general. This is demonstrated by
			my personal results, the way I think and broadcast my thoughts
			publicly on the channel and daily live broadcasts, and of course, by the road I personally passed: from a bunch of breached accounts to closing new ones
			accounts without stoplosses, from losing all accounts in 1 day to
			a full-fledged comeback in a few weeks and from someone who started walking alone
			blindfolded, and to the one whose eyes have opened at least a little.`,
			text2: `The work is carried out individually 1 on 1 with me and until the result. It can be receiving a live account and the first payouts,
			and just stability and faith in the positions you open.
			The work is completely without a time frame, until the result.`,
			subBlock1: {
				heading: 'XAU & INDICES',
				text: `Full study of the technical components of Gold and indices trading.`,
			},
			subBlock2: {
				heading: 'HOMEWORK',
				text: `Your performance and my checking of practical tasks.`,
			},
			subBlock3: {
				heading: 'BACKTEST',
				text: `Correct and high-quality backtest.`,
			},
			subBlock4: {
				heading: 'SYSTEM',
				text: `Construction and systematic analysis of your trade journal and TS.`,
			},
			subBlock5: {
				heading: '0 EMOTIONS',
				text: `Working with emotional impact and decision-making.`,
			},
			subBlock6: {
				heading: 'GUIDANCE',
				text: `Support at the stage of passing the challenge and before the first payouts in live.`,
			},
			subBlock7: {
				heading: 'MENTALITY',
				text: `Winning mentality and my vision.`,
			},
			subBlock8: {
				heading: 'DISCORD',
				text: `Lifetime access to the closed server and materials in Notion.`,
			},
			bookPlace: 'start',
			plan: 'WORK PLAN',
		},
		results: {
			heading: 'RESULTS & REVIEWS',
			info: 'REVIEWS ARE NOT TRANSLATED TO PRESERVE CONTEXT.',
			info2: '>40% OF OUR TRADERS ARE FUNDED.',
			next: 'BECOME THE NEXT ONE',
		},
		modal: {
			or: 'OR',
			name: 'NAME (OPTIONAL)',
			contact: 'TELEGRAM / PHONE NUMBER',
			month: '3 MONTHS',
			send: 'SEND',
			support: 'TEXT SUPPORT',
			thanks: 'THANKS. WE WILL CONTACT YOU SOON.',
		},
		affiliate: {
			step1: {
				p1: 'SIGN UP BY OUR',
				p2: 'LINKS',
			},
			step2: {
				p1: 'BUY YOUR FIRST',
				p2: 'PROP-ACCOUNT',
			},
			step3: {
				p1: 'TEXT OUR ',
				p2: 'SUPPORT',
			},
			step4: {
				p1: 'GET DISCOUNT',
				p2: 'OR CASHBACK',
			},
			cashback: '4% Cashback',
			discount: '5% Discount',
			discount2: '10% Discount',
			note: `The discount or cashback applies to the first purchase from a new registered user
			account via our link. For repeated discount/cashback -
			register a new account using the link, make a purchase from it and
			text our support. Discount is given immediately, cashback later after
			affiliate payment to us.`,
		},
		indicators: {
			heading: 'GOSHAWTY INDICATORS',
			subheading:
				'ALL INDICATORS ARE DEVELOPED AND USED BY ME PERSONALLY EVERY DAY.',
			add: 'Add indicator',
			guide: 'Video guide',
			list: [
				{
					id: 1,
					name: 'GOSHAWTY LOT SIZE CALCULATOR',
					shortInfo: 'LOT SIZE CALCULATOR FOR FOREX, INDEXES AND METALS',
					longInfo:
						'The indicator makes it possible to instantly calculate the lot size for any pair by setting only the stop and the entry point.',
					scriptURL:
						'https://www.tradingview.com/script/ig3zt1fE-GoShawty-Lot-Size-Calculator/',
					guideURL: 'https://youtu.be/kYldk9bwP6E?t=274',
				},
				{
					id: 2,
					name: 'GOSHAWTY FOREX SESSIONS',
					shortInfo: 'FX Sessions Indicator + Fractals',
					longInfo:
						'The indicator supports the main trading sessions: Asia, Frankfurt, London, New York. Also shows fractals.',
					scriptURL:
						'https://www.tradingview.com/script/lEpBLbbZ-GoShawty-Sessions/',
					guideURL: 'https://youtu.be/kYldk9bwP6E?t=20',
				},
				{
					id: 3,
					name: 'GOSHAWTY ULTIMATE',
					shortInfo: 'All Sessions Indicator + Fractals + Liquidity',
					longInfo:
						'The indicator supports all trading sessions: Asia, Frankfurt, London, New York AM and PM session. Shows fractals and PDWMHL.',
					scriptURL:
						'https://www.tradingview.com/script/sN2kjiEw-GoShawty-Ultimate/',
					guideURL: 'https://youtu.be/kYldk9bwP6E?t=149',
				},
				{
					id: 4,
					name: 'GOSHAWTY INDEX SESSIONS',
					shortInfo:
						'Indicator Index Sessions + Fractals + Silver Bullet + Macros',
					longInfo:
						'The indicator supports index trading sessions: London, New York AM and PM session. Shows fractals, macro and Silver Bullet timings.',
					scriptURL:
						'https://www.tradingview.com/script/PdyT22uI-GoShawty-Index-Sessions/',
					guideURL: 'https://youtu.be/kYldk9bwP6E?t=47',
				},
				{
					id: 5,
					name: 'GOSHAWTY RANGE TRACKER',
					shortInfo: '4-hour ranges indicator',
					longInfo:
						'The indicator shows 4-hour ranges, also supports CME timings of the beginning of the formation of candles.',
					scriptURL:
						'https://www.tradingview.com/script/oEXtoa8e-GoShawty-Range-Tracker/',
				},
				{
					id: 6,
					name: 'GOSHAWTY WATERMARK',
					shortInfo: 'Watermark for 2 rows',
					longInfo:
						'Just a watermark for 2 rows so that you can insert an ad in the 2nd row.',
					scriptURL:
						'https://www.tradingview.com/script/C1P3eXs3-GoShawty-Watermark/',
				},
			],
		},
	},
};

export const TITLES = {
	ua: {
		community: 'Forex Live-Трейдинг | GoShawty',
		mentorship: 'Менторшип | GoShawty',
		results: 'Результати Учасників | GoShawty',
		indicators: 'Індикатори | GoShawty',
	},
	ru: {
		community: 'Forex Live-Трейдинг | GoShawty',
		mentorship: 'Менторшип | GoShawty',
		results: 'Результаты Участников | GoShawty',
		indicators: 'Индикаторы | GoShawty',
	},
	en: {
		community: 'Forex Live-Trading | GoShawty',
		mentorship: 'Mentorship | GoShawty',
		results: "Members' Results | GoShawty",
		indicators: 'Indicators | GoShawty',
	},
};
