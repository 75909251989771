import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Language } from '../../hooks/useLanguage';
import styled from 'styled-components';
import { LANGUAGE_OPTIONS } from '../../lang/community';
import { getNewLangRoute } from '../../utils/utils';

type LanguagesProps = {
	language: Language;
	isUkr: boolean;
	size?: 'big' | 'small';
	onClick?: () => void;
};

export const Languages: FC<LanguagesProps> = ({
	language,
	isUkr,
	size,
	onClick,
}) => {
	const location = useLocation();

	return (
		<LangSection>
			<LangOption
				onClick={() => onClick && onClick()}
				to={getNewLangRoute('', location)}
				selected={isUkr}
				size={size}
				draggable="false"
			>
				UA
			</LangOption>
			<LangOption
				onClick={() => onClick && onClick()}
				to={getNewLangRoute('ru', location)}
				selected={language === LANGUAGE_OPTIONS[1]}
				size={size}
				draggable="false"
			>
				RU
			</LangOption>
			<LangOption
				onClick={() => onClick && onClick()}
				to={getNewLangRoute('en', location)}
				selected={language === LANGUAGE_OPTIONS[2]}
				size={size}
				draggable="false"
			>
				EN
			</LangOption>
		</LangSection>
	);
};

const LangSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 200px;
`;

const LangOption = styled(Link)<{ selected?: boolean; size?: 'big' | 'small' }>`
	font-size: ${({ size }) => (size === 'big' ? '28px' : '18px')};
	font-family: 'Roboto Slab', serif;
	padding: 5px;
	background: ${(p) => (p.selected ? p.theme.redDark : 'transparent')};
	color: ${(p) => (p.selected ? p.theme.blackText : p.theme.whiteText)};
	text-decoration: none;
	transition: 0.1s;
	pointer-events: ${(p) => (p.selected ? 'none' : 'default')};

	&:hover {
		background: ${(p) => (p.selected ? p.theme.whiteText : p.theme.whiteText)};
		color: ${(p) => p.theme.blackText};
	}
`;
