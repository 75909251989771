import React, { FC, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import redLogo from '../../assets/redLogo.png';
import ftmo from '../../assets/ftmoLogo.png';
import fp from '../../assets/fundingPipsLogo.png';
import spice from '../../assets/spice.png';
import funderpro from '../../assets/funderpro.png';

import { Tooltip } from 'react-tooltip';
import { openInNewTab } from '../../utils/utils';
import { useLanguage } from '../../hooks/useLanguage';
import { LANG_TEXT } from '../../lang/community';

const getTooltip = (text: string, anchor: string) => (
	<Tooltip
		anchorSelect={`.${anchor}`}
		place="right"
		style={{
			backgroundColor: 'black',
			color: '#fff',
			borderRadius: '0px',
			fontSize: '12px',
		}}
	>
		{text}
	</Tooltip>
);

type PopupProps = {
	isFloaterShown: boolean;
};

export const Popup: FC<PopupProps> = ({ isFloaterShown }) => {
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const language = useLanguage();

	const relocate = (link: string) =>
		setTimeout(() => {
			openInNewTab(link);
		}, 700);

	return (
		<>
			<Triangle isOpen={!isPopupOpen} isFloaterShown={isFloaterShown} />
			<Button
				onClick={() => setIsPopupOpen(!isPopupOpen)}
				isFloaterShown={isFloaterShown}
			>
				<img src={redLogo} alt="GoShawty Logo" draggable="false" />
			</Button>
			<Overlay
				isOpen={isPopupOpen}
				onClick={(e) => {
					setIsPopupOpen(false);
				}}
			>
				<PopupContent onClick={(e) => e.stopPropagation()}>
					<h1>
						1. {LANG_TEXT[language].affiliate.step1.p1} <br />
						{LANG_TEXT[language].affiliate.step1.p2}.
					</h1>
					<h1>
						2. {LANG_TEXT[language].affiliate.step2.p1} <br />{' '}
						{LANG_TEXT[language].affiliate.step2.p2}.
					</h1>
					{/* <h1>
						3. {LANG_TEXT[language].affiliate.step3.p1}
						<Link
							draggable="false"
							to="https://t.me/goshawtyxx"
							target="_blank"
						>
							{LANG_TEXT[language].affiliate.step3.p2}
						</Link>
						.
					</h1> */}
					<h1>
						3. {LANG_TEXT[language].affiliate.step4.p1}
						<br />
						{LANG_TEXT[language].affiliate.step4.p2}.
					</h1>

					<PropButton
						className="ftmo"
						onClick={() =>
							relocate(
								'https://trader.ftmo.com/?affiliates=rIJulYsAldsOYmXvONqn'
							)
						}
					>
						<img src={ftmo} alt="FTMO-logo" />
						<p>FTMO</p>
					</PropButton>
					{getTooltip(LANG_TEXT[language].affiliate.cashback, 'ftmo')}

					{getTooltip(LANG_TEXT[language].affiliate.discount2, 'funderpro')}
					<PropButton
						className="funderpro"
						onClick={() =>
							relocate(
								'https://prop.funderpro.com/buy-challenge/?referral=f5f0853f'
							)
						}
					>
						<img src={funderpro} alt="funderpro" />
						<p>FunderPro</p>
					</PropButton>

					{getTooltip(LANG_TEXT[language].affiliate.discount, 'spice')}
					<PropButton
						className="spice"
						onClick={() =>
							relocate(
								'https://trader.spiceprop.com/?affid=202410099&f=GS_FIRST&s=GOSHAWTY'
							)
						}
					>
						<img src={spice} alt="spice" />
						<p>Spice Prop</p>
					</PropButton>

					{getTooltip(LANG_TEXT[language].affiliate.discount, 'fp')}
					<PropButton
						className="fp"
						onClick={() =>
							relocate('https://app.fundingpips.com/register?ref=fe6b007d')
						}
					>
						<img src={fp} alt="FP-logo" />
						<p>Funding Pips</p>
					</PropButton>

					<h2>{LANG_TEXT[language].affiliate.note}</h2>
				</PopupContent>
			</Overlay>
		</>
	);
};

const breatheAnimation = keyframes`
 0% { transform: translateY(0px)}
 50% { transform: translateY(-5px)}
 100% { transform: translateY(0px)}
`;

const Triangle = styled.div<{ isOpen: boolean; isFloaterShown: boolean }>`
	position: fixed;
	bottom: 135px;
	z-index: 100000;
	right: 45px;
	background: ${({ theme }) => theme.blackBg};
	cursor: pointer;
	mix-blend-mode: difference;
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 10px solid ${({ theme }) => theme.whiteBg};
	transform: ${({ isOpen }) => !isOpen && 'translateY(20px)'};
	opacity: ${({ isOpen, isFloaterShown }) => (!isOpen || !isFloaterShown) && 0};
	transition: 0.2s;
	animation: ${breatheAnimation} 2s infinite;
`;

const Button = styled.div<{ isFloaterShown: boolean }>`
	backdrop-filter: blur(3px);
	-webkit-backdrop-filter: blur(3px);
	position: fixed;
	bottom: 60px;
	z-index: 100000;
	right: 20px;
	background: ${({ theme }) => theme.whiteBg};
	width: 70px;
	height: 70px;
	padding: 10px;
	cursor: pointer;
	mix-blend-mode: difference;
	border: 1px solid ${({ theme }) => theme.red};
	opacity: ${({ isFloaterShown }) => !isFloaterShown && 0};
	transition: 0.2s;

	img {
		width: 100%;
	}
`;

const Overlay = styled.div<{ isOpen: boolean }>`
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	z-index: 10000;
	position: fixed;
	width: 100%;
	height: 100%;
	visibility: ${({ isOpen }) => !isOpen && 'hidden'};
	opacity: ${({ isOpen }) => !isOpen && 0};
	backdrop-filter: blur(3px);
	-webkit-backdrop-filter: blur(3px);
	transition: 0.3s;
`;

const PopupContent = styled.div`
	display: flex;
	flex-direction: column;
	background: ${({ theme }) => theme.whiteBg};
	padding: 30px 30px;
	z-index: 10000;
	margin-bottom: 140px;
	margin-right: 20px;
	border: 2px solid ${({ theme }) => theme.blackBg};
	z-index: 100000000;

	h2 {
		margin-top: 30px;
		max-width: 270px;
		font-size: 9px;
		font-weight: normal;
		color: #3e3e3e;
	}

	* {
		user-select: none;
	}

	a {
		color: ${({ theme }) => theme.red};
	}

	@media (max-width: 500px) {
		padding: 20px;

		h2 {
			margin-top: 20px;
		}
	}
`;

const PropButton = styled.div`
	display: flex;
	align-items: center;
	border: 2px solid ${({ theme }) => theme.blackBg};
	padding: 10px;
	cursor: pointer;
	width: fit-content;
	margin-top: 10px;

	&:nth-of-type(1) {
		margin-top: 25px;
	}

	&:hover {
		background: ${({ theme }) => theme.blackBg};
		color: ${({ theme }) => theme.whiteBg};

		img {
			filter: invert(0);
		}
	}

	img {
		width: 100%;
		filter: invert(1);
		width: 30px;
	}

	p {
		margin-left: 10px;
		font-size: 14px;
	}
`;
